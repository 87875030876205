import { Component, ElementRef, ViewChild, OnInit, AfterContentInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { AzureBlobTokenInfo } from 'src/app/model/azure-blob-token-info';
import { CustomerInfo } from 'src/app/model/customer-info';
import { BlobStorageService } from 'src/app/service/blob-storage.service';
import { LoginService } from 'src/app/service/login.service';
import { UserService } from 'src/app/service/user.service';
import { UtilsService } from 'src/app/service/utils.service';
import { environment } from 'src/environments/environment';
import { EucarisInfo } from '../../model/eucaris-info';
import { EucarisService } from '../../service/eucaris.service';
import { MessageService } from '../../service/message.service';
import { UploadFileService } from '../../service/upload-file.service';
import { DateRangePickerComponent } from '../utils/date-range-picker/date-range-picker.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../service/toast.service';
import { CommonModule } from '@angular/common';
import { DragAndDropFileComponent } from '../utils/drag-and-drop-file/drag-and-drop-file.component';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DragAndDropFileComponent, DateRangePickerComponent],
})
export class UploadFileComponent implements OnInit, AfterContentInit {
  @ViewChild('progressBar')
  progressBar!: ElementRef<HTMLElement>;

  @ViewChild('daAlDatePicker')
  daAlDatePicker!: DateRangePickerComponent;

  currentUserEmail!: string;

  uploadFileForm = new FormGroup({
    file: new FormControl(),
    email: new FormControl(),
    nVerbali: new FormControl(),
    da: new FormControl(null, Validators.required),
    al: new FormControl(null, Validators.required),
    type: new FormControl(),
    eucarisUsername: new FormControl({ value: '', disabled: true }),
    eucarisPassword: new FormControl({ value: '', disabled: true }),
    vpnUsername: new FormControl({ value: '', disabled: true }),
    vpnPassword: new FormControl({ value: '', disabled: true }),
    privacyPolicy: new FormControl(),
    trackingStateChange: new FormControl(),
  });

  eucarisInfo: EucarisInfo | undefined;

  sasToken: AzureBlobTokenInfo | undefined;
  blobServiceClient: BlobServiceClient | undefined;
  blobContainerClient: ContainerClient | undefined;

  fileErrorMessage = '';

  privacyErrorMessage = '';
  eucarisPasswordErrorMessage = '';
  eucarisUsernameErrorMessage = '';

  vpnPasswordErrorMessage = '';
  vpnUsernameErrorMessage = '';

  uploadsType: string[] = [];

  hiddenEucaris = true;

  hiddenVpnCredentials = true;

  disableSubmit = false;
  uploading = false;
  hiddenProgress: any;
  lastUploadedFile: { fileName?: string; id?: string } = {};
  nomeFile: string = '';

  // editEucarisCredential = false;
  // editVPNCredential = false;

  loader: boolean = false;

  showEucarisPassword = false;

  showVPNPassword = false;
  enableEucarisCredentialsChange: boolean = false;

  enableVpnCredentialsChange: boolean = false;


  constructor(
    private uploadFileService: UploadFileService,
    private messageService: MessageService,
    private eucarisService: EucarisService,
    private loginService: LoginService,
    private utilsService: UtilsService,
    private userService: UserService,
    private blobStorageService: BlobStorageService,
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
  ) {
    this.userService.customerCode.subscribe((customer) => {
      if (customer)
        this.eucarisService.getEucarisInfo(customer.cod).subscribe({
          next: (resp) => {
            this.eucarisInfo = resp;

            this.initBlobConfig();
          },
          error: (err) => utilsService.httpErrorMessage(err),
        });
    });
  }

  editEucarisCredential() {
    if (this.uploadFileForm.controls.eucarisPassword.disabled) {
      this.uploadFileForm.controls.eucarisPassword.enable();
      this.uploadFileForm.controls.eucarisUsername.enable();
    } else {
      this.uploadFileForm.controls.eucarisPassword.setValue('');
      this.uploadFileForm.controls.eucarisUsername.setValue('');
      this.uploadFileForm.controls.eucarisPassword.disable();
      this.uploadFileForm.controls.eucarisUsername.disable();
    }
  }

  editVPNPassword() {
    if (this.uploadFileForm.controls.vpnPassword.disabled) {
      this.uploadFileForm.controls.vpnPassword.enable();
      this.uploadFileForm.controls.vpnUsername.enable();
    } else {
      this.uploadFileForm.controls.vpnUsername.setValue('');
      this.uploadFileForm.controls.vpnPassword.setValue('');
      this.uploadFileForm.controls.vpnPassword.disable();
      this.uploadFileForm.controls.vpnUsername.disable();
    }
  }

  ngOnInit() {
    this.loginService.getCurrentEmail().subscribe((resp) => {
      this.currentUserEmail = resp.email;
      this.uploadFileForm.controls.email.setValue(this.currentUserEmail);
    });

    this.uploadFileService.getUploadsType().subscribe((resp) => {
      const types = [''];
      types.push(...resp);

      this.uploadsType = types;

      this.uploadFileForm.controls.type.setValue('');
    });
  }

  ngAfterContentInit() {
    this.userService.uploadStatus.subscribe((resp) => {
      if (resp.isUploading && resp.percentage) {
        this.hiddenProgress = false;
        this.uploading = true;

        if (resp.uploadForm && resp.nomeFile) {
          this.uploadFileForm = resp.uploadForm;
          this.nomeFile = resp.nomeFile;
        }

        const percentage = resp.percentage.toFixed(2);

        if (this.progressBar) {
          this.loader = false;
          this.progressBar.nativeElement.style.width = `${percentage}%`;
          this.progressBar.nativeElement.textContent = `${percentage}%`;
          this.progressBar.nativeElement.setAttribute(
            'aria-valuenow',
            percentage.toString(),
          );
        } else {
          this.loader = true;
        }
      } else {
        this.hiddenProgress = true;
        this.uploading = false;
      }
    });
  }

  initBlobConfig() {
    this.blobContainerClient = undefined;

    const customerInfo = this.userService.customerCode.getValue();

    if (customerInfo)
      this.blobStorageService
        .getBlobToken(customerInfo.cod)
        .subscribe((resp) => {
          this.sasToken = resp;
          this.getBlobServiceClient();
        });
  }

  getBlobServiceClient() {
    if (this.sasToken) {
      this.blobServiceClient = new BlobServiceClient(
        `https://${this.sasToken.account}.blob.core.windows.net?${this.sasToken.token}`,
      );

      this.blobContainerClient = this.blobServiceClient.getContainerClient(
        this.sasToken.containerName,
      );
    }
  }

  async uploadFile() {
    const codCliente = this.userService.customerCode.getValue();

    if (
      (this.hiddenEucaris ||
        (this.uploadFileForm.controls.privacyPolicy.value &&
          this.uploadFileForm.controls.eucarisPassword.value &&
          this.uploadFileForm.controls.eucarisUsername.value)) &&
      this.blobContainerClient &&
      this.uploadFileForm.controls.file.value
    ) {
      // this.nomeFile = ;

      const blockBlobClient = this.blobContainerClient.getBlockBlobClient(
        `${
          this.eucarisInfo?.twoStep
            ? environment.tmpFolderName
            : environment.uploadFolderName
        }/${this.nomeFile}${this.eucarisInfo?.twoStep ? '.tmp' : ''}`,
      );

      const exist = await blockBlobClient.exists();

      // console.log('check exist file blob ', exist);

      const file = this.uploadFileForm.controls.file.value;

      if (exist) {
        this.fileErrorMessage = 'Esiste già un file con questo nome!';
        // this.renameHidden = false;
      } else {
        const self = this;

        this.userService.uploadStatus.next({
          isUploading: true,
          percentage: 0,
          uploadForm: this.uploadFileForm,
          nomeFile: this.nomeFile,
        });

        // console.log('blob');

        this.loader = true;

        blockBlobClient
          .uploadData(this.uploadFileForm.controls.file.value, {
            onProgress(progress) {
              self.userService.uploadStatus.next({
                isUploading: true,
                percentage: (100 / (file as File).size) * progress.loadedBytes,
                uploadForm: self.uploadFileForm,
                nomeFile: self.nomeFile,
              });
            },
          })
          .then(() => {
            // console.log('upload zure completato');

            this.sendForm(codCliente);
            sessionStorage.removeItem('isUploading');
          })
          .catch((error) => {
            // this.resetForm();
            this.uploading = false;
            sessionStorage.removeItem('isUploading');
          })
          .finally(() => {
            this.uploading = false;
            this.activeModal.close();
            this.disableSubmit = false;

          });
      }
    }
  }

  changeHiddenEucaris() {
    this.hiddenEucaris = !this.hiddenEucaris;
  }

  changeHiddenVpnCredentials() {
    this.hiddenVpnCredentials = !this.hiddenVpnCredentials;
  }

  checkEmailAndSendForm() {
    if (this.currentUserEmail != this.uploadFileForm.controls.email.value) {
      const message = this.messageService.openMessage(
        'Stai modificando le credenziali',
        `Con la presente si autorizza S21 in nome e per conto dell'ente richiedente all'utilizzo delle credenziali inserite per espletare le procedure di`,
        '',
        false,
        {
          leftActionButton: {
            label: 'Annulla',
            action: () => {
              message.close();
            },
          },
          rigthActionButton: {
            label: 'Conferma',
            action: () => {
              this.loginService
                .changeEmail(this.uploadFileForm.controls.email.value)
                .subscribe((resp) => {
                  this.uploadFile();
                  message.close();
                });
            },
          },
        },
      );
    } else {
      this.uploadFile();
    }
  }

  submit() {
    this.disableSubmit = true;
    this.checkEmailAndSendForm();
  }

  sendForm(codCliente: CustomerInfo | null) {

    if (
      this.nomeFile &&
      this.uploadFileForm.controls.nVerbali.value &&
      this.uploadFileForm.controls.type.value &&
      this.uploadFileForm.controls.file.value.name
    ) {
      const formData: any = new FormData();

      formData.append('filename', this.nomeFile);
      formData.append('nVerbali', this.uploadFileForm.controls.nVerbali.value);
      formData.append('type', this.uploadFileForm.controls.type.value);

      formData.append(
        'originalFileName',
        this.uploadFileForm.controls.file.value.name,
      );

      formData.append(
        'notifyStateChange',
        !this.uploadFileForm.controls.trackingStateChange.value ? false : true,
      );

      if (this.uploadFileForm.controls.da.value)
        formData.append('da', this.uploadFileForm.controls.da.value);
      if (this.uploadFileForm.controls.al.value)
        formData.append('al', this.uploadFileForm.controls.al.value);

      if (this.uploadFileForm.controls.eucarisPassword.enabled && this.uploadFileForm.controls.eucarisPassword.value) {
        if (this.uploadFileForm.controls.eucarisUsername.value) {
          formData.append(
            'userEucaris',
            this.uploadFileForm.controls.eucarisUsername.value,
          );
        } else {
          formData.append(
            'userEucaris',
            this.eucarisInfo?.eucarisUser,
          );
        }
        formData.append(
          'passEucaris',
          this.uploadFileForm.controls.eucarisPassword.value,
        );
      }

      if (
        this.uploadFileForm.controls.vpnPassword.enabled && this.uploadFileForm.controls.vpnPassword.value
      ) {
        if (this.uploadFileForm.controls.vpnUsername.value) {
          formData.append(
            'userVpn',
            this.uploadFileForm.controls.vpnUsername.value,
          );
        } else {
          formData.append(
            'userVpn',
            this.eucarisInfo?.vpnUser,
          );
        }

        formData.append(
          'passVpn',
          this.uploadFileForm.controls.vpnPassword.value,
        );
      }

      if (codCliente?.cod)
        // console.log(formData);


        this.uploadFileService.uploadFile(formData, codCliente.cod).subscribe({
          next: (resp) => {
            const codCliente = this.userService.customerCode.getValue()?.cod;

            this.toastService.show({
              classname: 'bg-toast-success border-success', delay: 5000, innerHtml: `<span class="p-2 rounded-circle bg-d1ebe2 me-2"><i class="fa-regular fa-thumbs-up text-success fa-xl"></i></span>
  Il file <span class="fw-bold">${formData.get('originalFileName')}</span> è stato aggiunto correttamente.`,
            });

            if (codCliente)
              this.eucarisService
                .getEucarisInfo(codCliente)
                .subscribe((resp) => {
                  if (resp) {
                    this.eucarisInfo = resp;
                    if (
                      this.eucarisInfo.eucarisInsertDays < 0 ||
                      this.eucarisInfo.eucarisInsertDays >= 90
                    ) {
                      this.hiddenEucaris = false;
                    } else {
                      this.hiddenEucaris = true;
                    }
                  }
                });

            this.loginService.getCurrentEmail().subscribe((resp) => {
              this.currentUserEmail = resp.email;
              this.uploadFileForm.controls.email.setValue(
                this.currentUserEmail,
              );
            });
            // console.log(resp);
          },
          error: (err) => {
            this.utilsService.httpErrorMessage(err);
            this.uploading = false;
            this.uploadFileForm.reset();
            this.daAlDatePicker.reset();
            this.nomeFile = '';
          },
          complete: () => {
            this.uploading = false;
            this.uploadFileForm.reset();
            this.daAlDatePicker.reset();
            this.nomeFile = '';
            // this.resetForm();
            this.userService.uploadStatus.next({
              isUploading: false,
              nomeFile: null,
              uploadForm: null,
              percentage: null,
            });
          },
        });
    } else {
      if (!this.uploadFileForm.controls.privacyPolicy.value) {
        this.privacyErrorMessage = 'Accetta la policy';
      } else {
        this.privacyErrorMessage = '';
      }

      if (!this.uploadFileForm.controls.eucarisPassword.value) {
        this.eucarisPasswordErrorMessage = 'Inserisci la password eucaris';
      } else {
        this.eucarisPasswordErrorMessage = '';
      }

      if (!this.uploadFileForm.controls.eucarisUsername.value) {
        this.eucarisUsernameErrorMessage = 'Inserisci username eucaris';
      } else {
        this.eucarisUsernameErrorMessage = '';
      }
    }
  }

  deleteLastFile() {
    if (this.lastUploadedFile.id) {
      this.uploadFileService.deleteFile(this.lastUploadedFile.id).subscribe({
        next: (resp) => {
          if (resp && resp.cod === '000') {
            this.toastService.show({
              classname: 'bg-toast-success border-success',
              delay: 5000,
              innerHtml:
                `<span class="p-2 rounded-circle bg-d1ebe2 me-2"><i class="fa-regular fa-thumbs-up text-success fa-xl"></i></span>Il file è stato eliminato`,
            });
          } else {
            this.toastService.show({
                closeButtonClass: 'text-danger',
                delay: 1000000,
                classname: 'bg-toast-error border-danger text-danger fw-semibold',
                innerHtml: `<span class="p-2 me-2"><i class="fa-solid fa-circle-exclamation fa-xl"></i></span>${resp.message}<br>Cod: ${resp.cod}`,
              },
            );
          }
        },
        error: (err) => this.utilsService.httpErrorMessage(err),
        complete: () => {
          this.lastUploadedFile = {};
        },
      });
    }
  }

  disableUpload() {
    if (!this.eucarisInfo) {
      return true;
    }

    if (!this.uploadFileForm.valid) {
      return true;
    }

    if (!this.hiddenEucaris) {
      if (
        !this.uploadFileForm.controls.eucarisPassword.value ||
        !this.uploadFileForm.controls.eucarisUsername.value ||
        !this.uploadFileForm.controls.privacyPolicy.value
      ) {
        return true;
      }
    }
    if (!this.hiddenVpnCredentials) {
      if (
        !this.uploadFileForm.controls.vpnPassword.value ||
        !this.uploadFileForm.controls.vpnUsername.value ||
        !this.uploadFileForm.controls.privacyPolicy.value
      ) {
        return true;
      }
    }

    if (
      !this.uploadFileForm.controls.file.value ||
      !this.uploadFileForm.controls.type.value ||
      !this.uploadFileForm.controls.nVerbali.value
    ) {
      return true;
    }

    if (
      this.uploadFileForm.controls.vpnPassword.enabled && !this.uploadFileForm.controls.vpnPassword.value
    ) {
      return true;
    }

    if (
      this.uploadFileForm.controls.eucarisPassword.enabled && !this.uploadFileForm.controls.eucarisPassword.value
    ) {
      return true;
    }

    return false;
  }
}
